import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormComponent } from './partials/form/form.component';
import { SurveyService } from './survey.component.service';
import { ActivatedRoute } from '@angular/router';
import { ThankyouComponent } from './partials/thankyou/thankyou.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SurveyTemplate } from './interfaces/survey.interface';

@Component({
  standalone: true,
  selector: 'app-survey',
  imports: [CommonModule, FormComponent, ThankyouComponent],
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent {
  customerLink: string = '';
  surveyId = 0;
  pageNotFound = false;
  isSubmited = false;
  surverTemplate: SurveyTemplate[] = [];
  constructor(
    private surveyService: SurveyService,
    private activatedRoute: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.ngxUiLoaderService.start();
    this.customerLink =
      this.activatedRoute.snapshot.paramMap.get('randomlink') ?? '';
    const type = this.activatedRoute.snapshot.paramMap.get('type') ?? 's';
    this.formatTemplat(type);
    this.getSurvey(type);
  }

  private getSurvey(type: string) {
    this.surveyService
      .getSurvey(`${type}/${this.customerLink}`)
      .subscribe((res) => {
        if (!res) {
          this.pageNotFound = true;
          this.ngxUiLoaderService.stop();
          return;
        }
        this.isSubmited = res.customer_has_submitted;
        this.surveyId = res.id;
        this.ngxUiLoaderService.stop();
        return;
      });
  }

  formatTemplat(type: string) {
    switch (type) {
      case 's':
        this.surverTemplate = [
          { question: '1. เลือกระดับความพึงพอใจในการให้บริการ' },
          {
            question:
              '2. ภายในเวลา 1 ปีล่าสุด คุณไปเที่ยวต่างประเทศมาแล้วกี่ครั้ง',
            answer: ['ไม่เคย', '1 ครั้ง', '2 ครั้ง', '3 ครั้งขึ้นไป'],
          },
          {
            question: '3. ความคิดเห็น/คำแนะนำที่อยากบอกเรา',
          },
        ];
        break;
      case 'cs':
        this.surverTemplate = [
          { question: '1. เลือกระดับความพึงพอใจในการให้บริการ' },
          {
            question: '2. ประเทศในใจอันดับหนึ่งของคุณ',
            answer: [
              'ยุโรป',
              'ญี่ปุ่น',
              'เวียดนาม',
              'ฮ่องกง หรือ สิงคโปร์',
              'เกาหลีใต้ หรือ ไต้หวัน',
              'อื่น ๆ',
            ],
          },
          {
            question: '3. ความคิดเห็น/ความประทับใจ/คำแนะนำที่อยากบอกเรา',
          },
        ];
        break;
    }
  }
}
