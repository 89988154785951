<div class="thankyou-container">
  <i (click)="close()" class="fa-solid fa-xmark right-corner"></i>
  <img
    class="thankyou-img"
    src="/assets/images/Tourwow-Feedback/TW-feedback-Thanks.png"
  />
  <div class="title">ขอบคุณ</div>
  <div class="description">
    ขอบคุณที่ร่วมทำแบบประเมินความพึงพอใจ
    เราจะนำข้อแนะนำของท่านมาปรับปรุงการให้บริการของบริษัททัวร์ว้าวค่ะ
  </div>
  <button (click)="close()" class="close-btn">ปิด</button>
  <div class="more-info">
    {{ "หากมีข้อแนะนำเพิ่มเติม ติดต่อได้ที่ @tourwow \nหรือ โทร. 02-6741500" }}
  </div>
</div>
