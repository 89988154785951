import { Component } from '@angular/core';

@Component({
  selector: 'app-thankyou',
  standalone: true,
  templateUrl: './thankyou.component.html',
  styleUrl: './thankyou.component.scss',
})
export class ThankyouComponent {
  close() {
    window.location.href = 'https://tourwow.com';
  }
}
