import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SurveyService } from '../../survey.component.service';
import { SurveyTemplate } from '../../interfaces/survey.interface';

@Component({
  standalone: true,
  selector: 'app-form',
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  constructor(private fb: FormBuilder, private surveyService: SurveyService) {}

  customerAnswer!: FormGroup;
  @Input() surveyId!: number;
  @Input() surveyTemplate!: SurveyTemplate[];
  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    const temp: any = {};
    for (let i = 1; i < 20; i++) {
      temp[`customer_answer_${i}`] = null;
    }
    this.customerAnswer = this.fb.group({
      ...temp,
      customer_answer_1: [null, Validators.required],
      customer_answer_2: [null, Validators.required],
    });
  }

  selectSatifyScore(score: number) {
    this.customerAnswer.patchValue({ customer_answer_1: score.toString() });
    return;
  }

  selectFrequencyScore(score: number) {
    this.customerAnswer.patchValue({ customer_answer_2: score.toString() });
    return;
  }

  checkSelectedBtn(question: number, answer: string) {
    return this.customerAnswer.value[`customer_answer_${question}`] === answer;
  }

  submitSurvey() {
    this.surveyService
      .submitSurvey(this.surveyId, this.customerAnswer.value)
      .subscribe((res) => {
        if (res.status === 'success') {
          window.location.reload();
        }
      });
  }
}
