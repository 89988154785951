import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ENDPOINT } from 'src/app/constants/endpoint.constant';
import { IResponse } from 'src/app/models/response.interface';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/services/base/http.service';
import { SurveyAnswer, SurveyResponse } from './interfaces/survey.interface';

@Injectable({
  providedIn: 'root',
})
export class SurveyService extends HttpService {
  constructor(
    @Inject(PLATFORM_ID) protected override platformId: Object,
    protected override http: HttpClient,
    protected override router: Router
  ) {
    super(platformId, http, router);
  }

  getSurvey(customerUrl: string): Observable<SurveyResponse> {
    const url = environment.apiUrl + ENDPOINT.SURVEY.BASE;
    const queryParam = this.getQueryString({
      customer_url_path: customerUrl,
    });

    return this.getRequestOptions(true).pipe(
      switchMap((headers) => {
        const options = {
          headers: headers.headers,
        };

        return this.http
          .get<IResponse<SurveyResponse>>(`${url}?${queryParam}`, options)
          .pipe(
            map((response: IResponse<SurveyResponse>) => {
              return response.data;
            })
          );
      })
    );
  }

  submitSurvey(id: number, body: SurveyAnswer) {
    const url = environment.apiUrl + ENDPOINT.SURVEY.BASE;

    return this.getRequestOptions(true).pipe(
      switchMap((headers) => {
        const options = {
          headers: headers.headers,
        };

        return this.http.put<IResponse<any>>(`${url}/${id}`, body, options);
      })
    );
  }
}
