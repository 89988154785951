<div *ngIf="!pageNotFound" class="justify-center">
  <div class="mobile-template">
    <app-form
      *ngIf="!isSubmited"
      [surveyId]="surveyId"
      [surveyTemplate]="surverTemplate"
    ></app-form>
    <app-thankyou *ngIf="isSubmited"></app-thankyou>
    <div class="footer">
      <img
        style="width: 85px"
        src="/assets/images/Tourwow-Feedback/TW-feedback-Logo.png"
      />
    </div>
  </div>
</div>
<div *ngIf="pageNotFound">404 Page Not Found</div>
