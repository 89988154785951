<div class="banner">
  <img
    style="width: 100%"
    src="/assets/images/Tourwow-Feedback/TW-feedback-Head.png"
  />
</div>

<div class="survey-container" [formGroup]="customerAnswer">
  <span class="survey-title">{{ surveyTemplate[0].question }}</span>
  <div class="satify-score-container">
    <div class="satify-score-box" *ngFor="let i of [1, 2, 3, 4, 5]">
      <button
        [ngClass]="{ 'selected-btn': checkSelectedBtn(1, i.toString()) }"
        (click)="selectSatifyScore(i)"
        class="satify-score-btn"
      >
        {{ i }}
      </button>
    </div>
  </div>
  <div class="satify-level">
    <span>ปรับปรุง</span>
    <span>พอใจมาก</span>
  </div>
  <span class="survey-title">{{ surveyTemplate[1].question }} </span>
  <div style="display: flex; justify-content: center; margin-bottom: 34px">
    <div class="frequency-container">
      <button
        *ngFor="let ans of surveyTemplate[1].answer; index as i"
        [ngClass]="{ 'selected-btn': checkSelectedBtn(2, (i + 1).toString()) }"
        (click)="selectFrequencyScore(i + 1)"
        class="frequency-btn"
      >
        <span>{{ ans }}</span>
      </button>
    </div>
  </div>
  <span class="survey-title">{{ surveyTemplate[2].question }}</span>
  <div style="margin-top: 16px">
    <textarea
      formControlName="customer_answer_3"
      placeholder="บอกเราเกี่ยวกับความคิดเห็นได้เลย"
      class="opinion-box"
      maxlength="1000"
    ></textarea>
  </div>
  <div style="margin-top: 34px">
    <button
      [ngClass]="{ 'activated-summit-btn': customerAnswer.valid }"
      class="submit-btn"
      [disabled]="!customerAnswer.valid"
      (click)="submitSurvey()"
    >
      ส่งแบบประเมิน
    </button>
  </div>
</div>
<div class="footer" style="z-index: -2">
  <img
    style="width: 100%"
    src="/assets/images/Tourwow-Feedback/TW-feedback-Footer.png"
  />
</div>
